.captionText,
#slide-6-layer-1 {
  z-index: 5;
  white-space: nowrap;
  font-size: 17px;
  line-height: 28px;
  font-weight: 400;
  color: rgba(241, 241, 241, 1);
  font-family: Lora;
}

.captionText1,
#slide-6-layer-2,
#slide-7-layer-2 {
  z-index: 6;
  white-space: nowrap;
  font-size: 50px;
  line-height: 58px;
  font-weight: 800;
  color: rgba(255, 255, 255, 1);
}

.captionText2,
#slide-7-layer-1 {
  z-index: 5;
  white-space: nowrap;
  font-size: 17px;
  line-height: 28px;
  font-weight: 400;
  color: rgba(229, 228, 228, 1);
  font-family: Lora;
}

.captionText3,
#slide-14-layer-15 {
  z-index: 9;
  padding-top: 35px;
  white-space: nowrap;
  font-size: 100px;
  line-height: 100px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  font-family: Lora;
}

.captionText3-bold,
#slide-14-layer-2 {
  z-index: 9;
  padding-top: 150px;
  white-space: nowrap;
  font-size: 100px;
  line-height: 100px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  border-color: #27498f;
  font-family: Lora;
}

.tp-bullets {
  display: none !important;
}

.member-name p :not(.no-member-space) {
  height: 130px !important;
}

.team-describe:hover .about-team-member {
  overflow-y: auto !important;
}

.member-name h2 {
  min-height: 52px;
}
